/* eslint-disable @next/next/no-img-element */
import { useMemo } from 'react';
import { NextPageWithLayout } from 'next';
import { lazyHydrate } from 'next-lazy-hydration-on-scroll';

import Image from 'next/legacy/image';

import LandingPageLayout from 'components/layout/landing/LandingPageLayout';
import nonAuth from 'utils/nonAuth';

import ProfileSetupIcon from 'public/images/landing2/profile-setup.svg';
import JobApplicationIcon from 'public/images/landing2/job-application.svg';
import CommunityIcon from 'public/images/landing2/icon_community.svg';
import PhotoMan2 from 'public/images/landing2/photo/2.png';
import BannerImage from 'public/images/landing/hero.webp';
import { useQuery } from 'react-query';
import axios from 'axios';

const PublicJobList = lazyHydrate(() => import('./PublicJobList'));
const FeaturesSection = lazyHydrate(() => import('./FeaturesSection'));
const PartnersSection = lazyHydrate(() => import('./PartnersSection'));
const CTASection = lazyHydrate(() => import('./CTASection'));

const Home: NextPageWithLayout = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['getPartners'],
    queryFn: () =>
      axios.get('https://directus.onboardcrewapp.com/items/partners'),
  });

  const partners = useMemo(() => {
    if (!isLoading) {
      return data?.data?.data ?? null;
    }
    return null;
  }, [data, isLoading]);

  return (
    <>
      <section
        className="min-h-screen relative flex items-center bg-primary px-16 ipad:bg-right ipad:px-96"
      >
        <Image
          src={BannerImage.src}
          alt=''
          objectFit='cover'
          layout='fill'
          priority
          placeholder='blur'
          blurDataURL={BannerImage.blurDataURL}
        />
        <div className="text-left w-full ipad:w-2/3 relative">
          <h1 className="text-title2 font-semibold text-content mb-16 ipad:text-title1">
            Empowering Seafarers, <br />
            Revolutionizing Maritime Recruitment
          </h1>
          <p className="text-regular3 text-content mb-8 ipad:text-xxlarge3">
            Your expert maritime career partner. Clear guidance, hassle-free
            applications, and fast jobs matching.
          </p>
        </div>
      </section>

      <section
        className="bg-primary px-16 pb-64 md:pb-100 ipad:px-96 pt-64 -mt-64"
        id="about"
      >
        <div>
          <p className="italic text-regular2 text-content mb-16 ipad:text-title3">
            About Us
          </p>
          <div className="flex flex-col gap-16 ipad:flex-row">
            <p className="text-xxlarge2 font-semibold text-content ipad:w-1/2 ipad:text-title2 ipad:leading-10">
              We are a maritime recruitment portal reimagined by the community, for the
              community.
            </p>
            <p className="text-regular3 md:text-xlarge3 text-content ipad:w-1/2">
              We connect seafarers with companies seamlessly, fostering trust
              and collaboration.
            </p>
          </div>
        </div>

        <div className="border-t border-grey my-32 md:my-64" />

        <div className="mb-40">
          <p className="italic text-content text-regular2 mb-24 ipad:text-title3 ipad:mb-32">
            All-in-One Jobs Matching Services
          </p>
          <div className="flex flex-col gap-20 ipad:flex-row">
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={ProfileSetupIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Create and organize seafarer profiles for recruitment.
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={JobApplicationIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Apply and manage job applications.
              </p>
            </div>
            <div className="bg-content rounded-lg p-16 ipad:w-1/3">
              <Image
                src={CommunityIcon}
                height={36}
                width={36}
                alt="illustration"
              />
              <p className="text-primary text-regular3 font-medium">
                Connect with the community, build a reputation, and get industry
                updates.
              </p>
            </div>
          </div>
        </div>

        <div className="border border-primary-lighter rounded-r-xl rounded-tl-xl flex gap-16 items-start p-16">
          <div className="flex justify-center items-center w-40 md:w-auto shrink-0">
            <Image
              src={PhotoMan2}
              height={100}
              width={100}
              alt="profile-photo"
              className="rounded-full"
            />
          </div>
          <div>
            <p className="italic text-content text-small3 md:text-large3 mb-24">
              &quot;The OnBoard application is easy to use. Hoping the OnBoard
              app can continue to grow so that seafarers can expand their
              network through this app.&quot;
            </p>
            <p className="text-content font-semibold text-small3 md:text-regular3">IP, Chief Officer</p>
          </div>
        </div>
      </section>

      <div className="wave-container" />

      <FeaturesSection />
      <PublicJobList />
      <PartnersSection partners={partners} />
      <CTASection />
    </>
  );
};

Home.getLayout = (page, props) => (
  <LandingPageLayout
    title='OnBoard Crew App | Portal Karir Untuk Pelaut'
    description='Temukan lowongan kerja pelaut terbaru di portal kerja khusus pelaut. Daftar sekarang untuk melamar pekerjaan di kapal pesiar, kapal kargo, kapal tanker, dan lainnya. Akses peluang karier maritim dengan mudah!'
    keywords='lowongan pelaut, kerja di kapal, job pelaut, lowongan kerja pelaut, portal kerja maritim, pekerjaan pelaut, lowongan kapal pesiar, lowongan kapal kargo, karier di laut, pekerjaan di kapal'
    {...props}
  >
    {page}
  </LandingPageLayout>
);

export default nonAuth(Home, false);
